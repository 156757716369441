// user Actions
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const IS_SIGNED_IN = 'IS_SIGNED_IN';
export const RELOAD_USER = 'RELOAD_USER';
export const SET_USER = 'SET_USER';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

// Vendor Actions
export const FETCH_VENDORS = 'FETCH_VENDORS';

// Notifications Actions
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_EDGES = 'FETCH_NOTIFICATIONS_EDGES';
export const FETCH_LATEST_NOTIFICATIONS = 'FETCH_LATEST_NOTIFICATIONS';

// Modal Actions
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const FEED_MODAL = 'FEED_MODAL';

// Transaction Actions
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TOTAL_SPENDING = 'FETCH_TOTAL_SPENDING';

// Integrations Actions
export const INTEGRATIONS_DATA_STATE = 'INTEGRATIONS_DATA_STATE';
export const FETCH_INTEGRATIONS = 'FETCH_INTEGRATIONS';
export const FETCH_INTEGRATIONS_EDGES = 'FETCH_INTEGRATIONS_EDGES';
export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const TOGGLE_ACTIVE_INTEGRATION = 'TOGGLE_ACTIVE_INTEGRATION';

// Savings Actions
export const FETCH_SAVINGS = 'FETCH_SAVINGS';
export const FETCH_SAVINGS_EDGES = 'FETCH_SAVINGS_EDGES';

// Resources Actions
export const FETCH_FINDING_RESOURCE = 'FETCH_FINDING_RESOURCE';
export const UPDATE_FINDING_RESOURCES = 'UPDATE_FINDING_RESOURCES';
export const UPDATE_RESOURCE_BY_REGION = 'UPDATE_RESOURCE_BY_REGION';
export const CURRENT_FINDING = 'CURRENT_FINDING';

// Security Actions
export const FETCH_SECURITIES = 'FETCH_SECURITIES';
export const FETCH_SECURITIES_EDGES = 'FETCH_SECURITIES_EDGES';

// Billing Actions
export const BILLING_REDIRECT = 'BILLING_REDIRECT';

// Insights Actions
export const FETCH_INSIGHTS = 'FETCH_INSIGHTS';

// ApiKeys Actions
export const FETCH_API_KEYS = 'FETCH_API_KEYS';
export const FETCH_ALLOWED_PERMISSIONS = 'FETCH_ALLOWED_PERMISSIONS';
export const CREATE_API_KEY = 'CREATE_API_KEY';
export const DELETE_API_KEY = 'DELETE_API_KEY';
export const UPDATE_API_KEY = 'UPDATE_API_KEY';

// Charts Actions
export const FETCH_EXPENSES_BY_VENDOR = 'FETCH_EXPENSES_BY_VENDOR';
export const FETCH_EXPENSES_BY_CATEGORY = 'FETCH_EXPENSES_BY_CATEGORY';
export const SET_MONTH_TOTALS = 'SET_MONTH_TOTALS';

// Intro Actions
export const SET_INTRO_INITIAL_STATE = 'SET_INTRO_INITIAL_STATE';

// Organization Actions
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const FETCH_ORGANIZATION_MEMBERS = 'FETCH_ORGANIZATION_MEMBERS';
export const FETCH_ORGANIZATION_INSIGHT = 'FETCH_ORGANIZATION_INSIGHT';
export const FETCH_ORGANIZATION_INVITATIONS = 'FETCH_ORGANIZATION_INVITATIONS';
export const UPDATE_CURRENT_ORGANIZATION = 'UPDATE_CURRENT_ORGANIZATION';
export const FETCH_DEFAULT_ORGANIZATION = 'FETCH_DEFAULT_ORGANIZATION';
export const FETCH_ORGANIZATION_EDGES = 'FETCH_ORGANIZATION_EDGES';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const SET_ORGANIZATION_PERSONAL_FLAG = 'SET_ORGANIZATION_PERSONAL_FLAG';
export const LEAVE_ORGANIZATION = 'LEAVE_ORGANIZATION';
export const CHANGE_ROLE = 'CHANGE_ROLE';
export const FETCH_ORGANIZATION_AFFILIATES = 'FETCH_ORGANIZATION_AFFILIATES';

// Navigation Actions
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';

// Accounts widget Actions
export const FETCH_ACCOUNTS_WIDGET = 'FETCH_ACCOUNTS_WIDGET';

// Identified threats widget Actions
export const FETCH_THREATS_IDENTIFIED = 'FETCH_THREATS_IDENTIFIED';

// Active threats widget Actions
export const FETCH_THREATS_ACTIVE = 'FETCH_THREATS_ACTIVE';

// Set Filters
export const SET_FILTERS = 'SET_FILTERS';
