/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {
  Badge,
  Button, Col, Dropdown, Form,
  Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpendingTotal, fetchTransactions } from '../../actions/spending/transactionsActions';
import { fetchThreatsActive, fetchThreatsIdentified } from '../../actions/dashboard/widgetsActions';

import 'react-datepicker/dist/react-datepicker.css';
import { fetchSavings } from '../../actions/dashboard/savingsActions';
import { fetchSecurities } from '../../actions/dashboard/securityActions';
import Switch from '../common/inputs/baseSwitch';
import './styles.css';
import { SET_FILTERS } from '../../actions/types';
import { vendors } from '../../constants/clouds';
import BaseMultipleOtionsDropdown from '../common/inputs/baseDropdown';
import SearchableDropdown from '../common/inputs/searchableDropdown';
import { fetchIntegrations } from '../../actions/dashboard/integrationsActions';

const vendorsOptions = [vendors.AWS, vendors.Azure, vendors.GCP, vendors.OCI];

function DashboardFilters({ t, organizationId }) {
  const dispatch = useDispatch();
  const { filters: filtersState, vendors: vendorsState, integrations } = useSelector((state) => state);

  const integrationsList = integrations?.all?.result.map((integration) => integration.name) || [];
  const selectedTransaction = filtersState.integration;

  const onFiltersSubmit = async () => {
    const {
      inUse, filterSwitch, integration, ...filters
    } = filtersState;

    const integrationId = integrations?.all?.result.find((integrationItem) => integrationItem.name === integration)?._id;

    const threatFiltersFormat = {
      filters: {
        start_date: filtersState.start,
        end_date: filtersState.end,
        type: filtersState.type,
        vendors: filters.vendors,
        integrationId,
      },
    };
    await dispatch(fetchTransactions({
      organizationId,
      limit: 'all',
      dataFilter: {
        ...filters,
        integrationId,
      },
    }));
    await dispatch(fetchThreatsIdentified({
      organizationId,
      ...threatFiltersFormat,
    }));
    await dispatch(fetchThreatsActive({
      organizationId,
      ...threatFiltersFormat,
    }));

    await dispatch(fetchSavings({
      organizationId,
      dataFilter: {
        ...(integrationId && {
          integrationId,
        }),
        ...filters,
      },
    }));
    await dispatch(fetchSecurities({
      organizationId,
      dataFilter: {
        ...(integrationId && {
          integrationId,
        }),
        ...filters,
      },
    }));
    await dispatch(fetchSpendingTotal({
      organizationId,
      filters: {
        ...(integrationId && {
          integrationId,
        }),
        ...filters,
      },
    }));
  };

  const onVendorsChange = (selectedVendors) => {
    const endVendors = selectedVendors.map((vendor) => {
      const foundVendor = vendorsState.find((vendorInStore) => vendorInStore.name === vendor);

      if (foundVendor) {
        return foundVendor._id;
      }
      return {
        ...vendor,
      };
    });
    dispatch({ type: SET_FILTERS, payload: { vendors: endVendors } });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-header-title my-2">{t('views.dashboard.filters.title')}</h4>
            <Switch
              isOn={filtersState.filterSwitch}
              handleToggle={() => dispatch({ type: SET_FILTERS, payload: { filterSwitch: !filtersState.filterSwitch } })}
            />
          </div>
          {
            filtersState.filterSwitch ? (
              <div className="card-body animated-fade">
                <Row xs={2} md={4} lg={8}>
                  <Col>
                    <Form.Select
                      className="form-control choose-finding-type"
                      onChange={(evt) => dispatch({ type: SET_FILTERS, payload: { type: evt.target.value } })}
                    >
                      <option>{t('views.dashboard.filters.findingTypeLabel')}</option>
                      <option value="Security">{t('views.dashboard.security.title')}</option>
                      <option value="Saving">{t('components.organizations.organizationCard.savings')}</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <BaseMultipleOtionsDropdown
                      optionsList={vendorsOptions}
                      onSelectionChange={(selectedVendors) => onVendorsChange(selectedVendors)}
                    />
                  </Col>
                  <Col className="d-flex">
                    <SearchableDropdown
                      title={t('views.dashboard.filters.integrationLabel')}
                      onSearch={(keyword) => keyword.length > 0 && dispatch(fetchIntegrations({ organizationId, dataFilter: { filter: keyword } }))}
                      optionsList={integrationsList}
                      onSelectionChange={(e) => dispatch({ type: SET_FILTERS, payload: { integration: e } })}
                      isLoading={integrations.isLoading}
                    />
                    {
                      selectedTransaction ? (
                        <div className="d-flex justify-content-center align-items-center ml-3">
                          <Badge pill bg="secondary" onClick={() => dispatch({ type: SET_FILTERS, payload: { integration: null } })}>
                            {selectedTransaction}
                            {' '}
                            <i className="fa-solid fa-circle-xmark" color="white" />
                          </Badge>

                        </div>

                      ) : null
                    }
                  </Col>
                </Row>
                <br />
                <Dropdown.Divider />
                <div className="row ml-0">
                  <Col xs={6} md={6} lg={2} className="filter-col-style my-3">
                    <h4 className="card-header-title mr-2">{t('views.dashboard.filters.startDateLabel')}</h4>
                    <DatePicker
                      className="def-date-picker form-control"
                      selected={filtersState.start}
                      onChange={(date) => dispatch({ type: SET_FILTERS, payload: { start: date } })}
                    />
                  </Col>
                  <Col xs={6} md={6} lg={2} className="filter-col-style my-3">
                    <h4 className="card-header-title mr-2">{t('views.dashboard.filters.endDateLabel')}</h4>
                    <DatePicker
                      className="def-date-picker form-control"
                      selected={filtersState.end}
                      onChange={(date) => dispatch({ type: SET_FILTERS, payload: { end: date } })}
                    />
                  </Col>
                </div>

                <Col
                  xs={12}
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    variant="primary"
                    onClick={() => onFiltersSubmit()}
                  >
                    {t('views.dashboard.filters.submitFilters')}
                  </Button>
                </Col>
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  );
}

DashboardFilters.propTypes = {
  t: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
};

export default DashboardFilters;
