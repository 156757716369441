import React, { useState, useCallback } from 'react';
import {
  Dropdown, DropdownButton, ButtonGroup, FormControl, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

function SearchableDropdown({
  title, optionsList, onSelectionChange, onSearch, isLoading,
}) {
  const [searchQuery, setSearchQuery] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce((keyword) => {
    onSearch(keyword);
  }, 500), [onSearch]);

  const onHandleSearch = (keyword) => {
    setSearchQuery(keyword);
    debouncedSearch(keyword);
  };

  return (
    <DropdownButton
      className="dropdown-button-style"
      as={ButtonGroup}
      id="dropdown-basic-button"
      title={(
        <>
          {title}
          <span className="ml-2 mr-1">
            <i className="fa fa-angle-down" style={{ width: 9 }} />
          </span>
        </>
      )}
    >
      <FormControl
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => onHandleSearch(e.target.value)}
        className="mx-3 my-2 w-auto"
      />
      {isLoading ? (
        <div className="text-center my-2">
          <Spinner animation="border" size="sm" />
        </div>
      ) : (
        optionsList.map((item) => (
          <Dropdown.Item
            key={item}
            onClick={(e) => onSelectionChange(e.target.text)}
          >
            {item}
          </Dropdown.Item>
        ))
      )}
    </DropdownButton>
  );
}

export default SearchableDropdown;

SearchableDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  optionsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
